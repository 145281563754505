import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format
} from 'date-fns';
import { EMAIL_REG_EXP, TWENTY_FOUR_HOUR_FORMAT } from './constants';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import {
  ALERT,
  FILE_EXTENSIONS,
  RESOURCE_PROVIDERS,
  SUPPORTED_UPLOAD_FILES_FORMAT
} from '../types/enum';
import { ReactComponent as PdfIcon } from 'src/assets/documentIcons/pdf-icon.svg';
import { ReactComponent as SPPdfIcon } from 'src/assets/documentIcons/pdf-sharepoint-icon.svg';
import { ReactComponent as ODPdfIcon } from 'src/assets/documentIcons/pdf-onedrive-icon.svg';
import { ReactComponent as PptIcon } from 'src/assets/documentIcons/ppt-icon.svg';
import { ReactComponent as SPPptIcon } from 'src/assets/documentIcons/ppt-sharepoint-icon.svg';
import { ReactComponent as ODPptIcon } from 'src/assets/documentIcons/ppt-onedrive-icon.svg';
import { ReactComponent as MsWordIcon } from 'src/assets/documentIcons/ms-word-icon.svg';
import { ReactComponent as SPMsWordIcon } from 'src/assets/documentIcons/ms-word-sharepoint-icon.svg';
import { ReactComponent as ODMsWordIcon } from 'src/assets/documentIcons/ms-word-onedrive-icon.svg';
import { ReactComponent as ExcelIcon } from 'src/assets/documentIcons/excel-icon.svg';
import { ReactComponent as SPExcelIcon } from 'src/assets/documentIcons/excel-sharepoint-icon.svg';
import { ReactComponent as ODExcelIcon } from 'src/assets/documentIcons/excel-onedrive-icon.svg';
import { IResourceProvider } from 'src/types/api';
import { ErrorCodes } from './errorMappings';
import { AlertColor } from '@mui/material';
import get from 'lodash/get';
import { StringKeys } from 'src/types/base';

const AZURE_PORTAL_URL = 'https://portal.azure.com/#home';
export const getInitials = (name: string) => {
  // Sanitize the string:
  const sanitizedStr = name
    .trim() // Remove leading/trailing spaces
    .replace(/\s+/g, ' ') // Replace multiple spaces with single space
    .replace(/[^a-zA-Z0-9' ]/g, ' ') // Replace non-alphanumeric characters with space and allowing apostrophy
    .toUpperCase() // Convert to uppercase for consistency
    .split(' ') // Split the string on space
    .slice(0, 2); // Select only first 2 words

  // Get the initials:
  if (sanitizedStr.length === 1) {
    return sanitizedStr[0].charAt(0) + sanitizedStr[0].charAt(1);
  }
  return sanitizedStr.map((word) => word.charAt(0)).join('');
};

export const formatDate = (date?: string | Date, date_format?: string) => {
  return date
    ? format(new Date(`${date}`), date_format || TWENTY_FOUR_HOUR_FORMAT)
    : '';
};

export const caseInsensitiveSort = (a: string, b: string) => {
  return a.toLowerCase().localeCompare(b.toLowerCase());
};

export const viewPDFDocument = (data) => {
  const url = window.URL.createObjectURL(data);
  window.open(url);
};

export const convertDate = (date: Date, t: any) => {
  let currentDate: any = new Date();
  let previousDate: any = new Date(date);

  const days = differenceInDays(currentDate, previousDate);
  const hours = differenceInHours(currentDate, previousDate);
  const mins = differenceInMinutes(currentDate, previousDate);
  const secs = differenceInSeconds(currentDate, previousDate);

  if (days > 0) {
    return `${days} ${t(days > 1 ? T.days : T.day)}`;
  } else if (hours > 0) {
    return `${hours} ${t(hours > 1 ? T.hours : T.hour)}`;
  } else if (mins > 0) {
    return `${mins} ${t(mins > 1 ? T.mins : T.min)}`;
  } else if (secs > 0) {
    return `${secs} ${t(secs > 1 ? T.secs : T.sec)}`;
  }
  return '';
};

const getProviderFileIcon = (externalType = '', iconType = '') => {
  let providerIcon = null;
  switch (externalType) {
    case RESOURCE_PROVIDERS.SHAREPOINT:
      switch (iconType) {
        case FILE_EXTENSIONS.PDF:
          providerIcon = SPPdfIcon;
          break;
        case FILE_EXTENSIONS.PPT:
          providerIcon = SPPptIcon;
          break;
        case FILE_EXTENSIONS.XLSX:
          providerIcon = SPExcelIcon;
          break;
        case FILE_EXTENSIONS.DOCX:
          providerIcon = SPMsWordIcon;
          break;
        default:
          break;
      }
      break;
    case RESOURCE_PROVIDERS.ONEDRIVE:
      switch (iconType) {
        case FILE_EXTENSIONS.PDF:
          providerIcon = ODPdfIcon;
          break;
        case FILE_EXTENSIONS.PPT:
          providerIcon = ODPptIcon;
          break;
        case FILE_EXTENSIONS.XLSX:
          providerIcon = ODExcelIcon;
          break;
        case FILE_EXTENSIONS.DOCX:
          providerIcon = ODMsWordIcon;
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
  return providerIcon;
};

export const getFileIcon = (fileType = '', providerType = '') => {
  switch (fileType?.toLowerCase?.()) {
    case SUPPORTED_UPLOAD_FILES_FORMAT.PDF:
    case FILE_EXTENSIONS.PDF:
      return providerType
        ? getProviderFileIcon(providerType, FILE_EXTENSIONS.PDF)
        : PdfIcon;
    case SUPPORTED_UPLOAD_FILES_FORMAT.PPT:
    case SUPPORTED_UPLOAD_FILES_FORMAT.PPTX:
    case SUPPORTED_UPLOAD_FILES_FORMAT.ODP:
    case FILE_EXTENSIONS.PPT:
    case FILE_EXTENSIONS.PPTX:
    case FILE_EXTENSIONS.ODP:
      return providerType
        ? getProviderFileIcon(providerType, FILE_EXTENSIONS.PPT)
        : PptIcon;
    case SUPPORTED_UPLOAD_FILES_FORMAT.DOC:
    case SUPPORTED_UPLOAD_FILES_FORMAT.DOCX:
    case SUPPORTED_UPLOAD_FILES_FORMAT.ODT:
    case FILE_EXTENSIONS.DOC:
    case FILE_EXTENSIONS.DOCX:
    case FILE_EXTENSIONS.ODT:
      return providerType
        ? getProviderFileIcon(providerType, FILE_EXTENSIONS.DOCX)
        : MsWordIcon;
    case SUPPORTED_UPLOAD_FILES_FORMAT.XLS:
    case SUPPORTED_UPLOAD_FILES_FORMAT.XLSX:
    case SUPPORTED_UPLOAD_FILES_FORMAT.ODS:
    case FILE_EXTENSIONS.XLS:
    case FILE_EXTENSIONS.XLSX:
    case FILE_EXTENSIONS.ODS:
      return providerType
        ? getProviderFileIcon(providerType, FILE_EXTENSIONS.XLSX)
        : ExcelIcon;

    default:
      return null;
  }
};

export const getFileType = (
  name: string,
  source: string,
  format: string,
  original_format: string
) => {
  if (original_format) return original_format;
  else if (name) return name?.split('.')?.pop();
  else if (format) return format;
  else if (source) return source?.split('.')?.pop();

  return '';
};

export const isEmailValid = (value: string) => {
  return !!value?.match(EMAIL_REG_EXP) || false;
};

// TODO: separate files and folders related utilities
export const getProviderForCurrentResource = (
  providerId: string,
  resourceProviders: IResourceProvider[]
): RESOURCE_PROVIDERS | undefined => {
  return resourceProviders.find(({ id }) => id === +providerId)?.type;
};

export const autoScrollIntoView = (errors: string[]) => {
  const firstErrorField = errors?.[0] ?? '';
  if (firstErrorField) {
    const field = document.getElementsByName(firstErrorField);
    if (field.length) {
      field?.[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
};

type OverLimitMessageType = {
  msg: string;
  type: AlertColor;
};

export const checkCreditsLimit = (licenseInfo: {
  creditLimit: string;
  wordLimit: string;
}): OverLimitMessageType[] => {
  let messages: OverLimitMessageType[] = [];

  if (licenseInfo.creditLimit === ErrorCodes.CREDIT_LIMIT_EXCEEDED_90_PERCENT) {
    messages.push({
      msg: T.creditLimitExceeded90Percent,
      type: ALERT.WARNING
    });
  } else if (
    licenseInfo.creditLimit === ErrorCodes.CREDIT_LIMIT_REACHED_100_PERCENT
  ) {
    messages.push({
      msg: T.chatCreditLimitExceededOA,
      type: ALERT.ERROR
    });
  }
  if (licenseInfo.wordLimit === ErrorCodes.WORD_LIMIT_EXCEEDED_90_PERCENT) {
    messages.push({
      msg: T.wordLimitExceeded90Percent,
      type: ALERT.WARNING
    });
  } else if (
    licenseInfo.wordLimit === ErrorCodes.WORD_LIMIT_REACHED_100_PERCENT
  ) {
    messages.push({
      msg: T.wordLimitReached100Percent,
      type: ALERT.ERROR
    });
  }

  return messages;
};

export const filterEmptyValuesFromList = (
  options: StringKeys,
  optionLabel: string
) =>
  options?.filter((option) => {
    const label =
      typeof option === 'string' ? option : get(option, optionLabel);
    return label && label.trim() !== '';
  });

export const getLanguageName = (language: string, code: string) => {
  if (code) {
    try {
      return new Intl.DisplayNames([language], { type: 'language' }).of(code)
    } catch (err) {
      return ''
    }
  }
  return ''
}

export const openAzurePortal = async () => {
  window.open(AZURE_PORTAL_URL, '_blank');
};

export const isValidURL = (url: string) => {
  const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/;
  return regex.test(url);
};
