import { FC, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Drawer,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';

import Sidebar from './Sidebar';
import { ReactComponent as SidebarIcon } from 'src/assets/sidebarIcon/sidebar-icon.svg';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { Main, drawerWidth, miniDrawerWidth } from './styles';
import UserOnboard from 'src/components/UserOnboard';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { isMobile } from 'react-device-detect';
import { NavLink } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';
import {
  closeDocumentViewer,
  createChatSnapshot,
  setSelectedChat
} from 'src/redux/slices/chat';
import { resetAssistantContext } from 'src/content/Documents/utils/gridUtils';

const AccentHeaderLayout: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);

  const [isShareChatPath, setIsShareChatPath] = useState<boolean>(true);
  const { isOnboarded, isLangInitialized } = useSelector(
    (state: RootState) => state.data
  );
  const { pathname } = useLocation();
  const { selectedChat } = useSelector((state: RootState) => state.chat);
  const dispatch = useDispatch();
  const { state: routeState } = useLocation();

  useEffect(() => {
    if (pathname)
      setIsShareChatPath(pathname.split('/').includes('share-chat'));
  }, [pathname]);

  const handleCreateNewChat = () => {
    if (selectedChat !== 0 || routeState) {
      dispatch(closeDocumentViewer(selectedChat));
      dispatch(setSelectedChat(undefined));
      dispatch(createChatSnapshot({ chatId: 0 }));
      resetAssistantContext(dispatch);
      setTimeout(() => {
        dispatch(setSelectedChat(0));
      }, 100);
    }
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />

      {!isShareChatPath && (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,

            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box'
            }
          }}
          variant={isMobile ? 'temporary' : 'persistent'}
          anchor="left"
          open={isSidebarOpen}
          onClose={toggleSidebar}
        >
          <Sidebar />
        </Drawer>
      )}

      {/* Desktop minimized version  */}
      <Drawer
        sx={{
          width: `${miniDrawerWidth}px`,
          flexShrink: 0,
          position: 'absolute',
          left: 0,
          [theme.breakpoints.down('sm')]: {
            display: 'none'
          },
          '& .MuiDrawer-paper': {
            width: `${miniDrawerWidth}px`,
            boxSizing: 'border-box',
            backgroundColor: theme.sidebar.background
          }
        }}
        variant="persistent"
        anchor="left"
        open={!isSidebarOpen}
      >
        <IconButton
          aria-label="open drawer"
          onClick={toggleSidebar}
          color="secondary"
          sx={{
            my: theme.spacing(2),
            mx: 'auto',
            ...(isSidebarOpen && { display: 'none' }),
            padding: theme.spacing(1.4)
          }}
        >
          <SidebarIcon height="24px" width="24px" />
        </IconButton>
      </Drawer>

      {/* Mobile minimized version */}
      <Drawer
        sx={{
          height: `${miniDrawerWidth}px`,
          flexShrink: 0,
          position: 'absolute',
          left: 0,
          [theme.breakpoints.up('sm')]: {
            display: 'none'
          },
          '& .MuiDrawer-paper': {
            height: `${miniDrawerWidth}px`,
            boxSizing: 'border-box'
          }
        }}
        variant="persistent"
        anchor="top"
        open={!isSidebarOpen && !isShareChatPath}
      >
        <Box
          display="flex"
          gap="12px"
          alignItems="center"
          height="100%"
          padding="16px"
        >
          <IconButton
            aria-label="open drawer"
            onClick={toggleSidebar}
            color="secondary"
            sx={{
              boxShadow: '0px 1.1px 3.5px 0 #1018282A'
            }}
          >
            <SidebarIcon
              height="24px"
              width="24px"
              stroke={theme.colors.primary.main}
            />
          </IconButton>

          <Button
            component={NavLink}
            variant="contained"
            color="secondary"
            sx={{
              borderRadius: '6px',
              flexGrow: 1
            }}
            to="/chat"
            size="medium"
            startIcon={<Add />}
            onClick={handleCreateNewChat}
          >
            <Typography variant="h4">{t(T.createNewChat)}</Typography>
          </Button>
        </Box>
      </Drawer>

      <Main isSideBarOpen={isSidebarOpen} isHidden={isShareChatPath}>
        <Container
          maxWidth={false}
          disableGutters
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Outlet />
          {!isOnboarded && isLangInitialized && <UserOnboard />}
        </Container>
      </Main>
    </Box>
  );
};

export default AccentHeaderLayout;
